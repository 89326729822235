import * as CryptoJS from 'crypto-js';

export type ExpireOptions = {
  expire: number | string | Date;
  lifetime?: number;
};

export type UploadSecureSignature = { secureSignature: string; secureExpire: string };

const msToUnixTimestamp = (ms: number): string => Math.floor(ms / 1000).toString();

const getSecureExpire = (options: ExpireOptions) => {
  if (options.expire) {
    return msToUnixTimestamp(new Date(options.expire).getTime());
  }

  return msToUnixTimestamp(Date.now() + (options.lifetime ?? 0));
};

export const generateSecureSignature = (secret: string, options: ExpireOptions): UploadSecureSignature => {
  const secureExpire = getSecureExpire(options);
  const secureSignature = CryptoJS.HmacSHA256(secureExpire, secret).toString(CryptoJS.enc.Hex);

  return { secureSignature, secureExpire };
};
