import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titlecase',
  standalone: true,
})
export class TitleCasePipe implements PipeTransform {
  transform(str: string | undefined): any {
    return str
      ?.toLowerCase() // Normalize to lowercase
      .replace(/-/g, ' ') // Replace hyphens with spaces
      .split(' ') // Split into words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join words back together
  }
}
