import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { createResubSource } from '@abbadox-monorepo/core-utils';

@Injectable({ providedIn: 'root' })
export class HttpBaseService {
  private readonly http = inject(HttpClient);

  get<T>(url: string, params: HttpParams = new HttpParams(), staleTime?: number): Observable<T> {
    return createResubSource(this.http.get<T>(`${url}`, { headers: this.headers, params }), staleTime);
  }

  post<T, D>(url: string, data?: D): Observable<T> {
    return this.http.post<T>(`${url}`, JSON.stringify(data), { headers: this.headers });
  }

  put<T, D>(url: string, data: D): Observable<T> {
    return this.http.put<T>(`${url}`, JSON.stringify(data), { headers: this.headers });
  }

  delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(`${url}`, { headers: this.headers });
  }

  get headers(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    return new HttpHeaders(headersConfig);
  }
}
