import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject } from '@angular/core';
import { Router } from '@angular/router';

import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { SelectEntityId, setAllEntities, withEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap, tap } from 'rxjs';

import { ApplicationNames } from '@abbadox-monorepo/core-api-interfaces';
import {
  CoreUiActions,
  setError,
  setLoaded,
  setLoading,
  withLogger,
  withRequestStatus,
  withSelectedEntity,
} from '@abbadox-monorepo/core-data-access';
import { getFutureDate, LocalStorageService } from '@abbadox-monorepo/core-utils';
import {
  LOCAL_STORAGE_KIOSK_CAREFLOW_CONVERTED_TOKEN_KEY,
  LOCAL_STORAGE_KIOSK_CAREFLOW_TOKEN_KEY,
  LOCAL_STORAGE_KIOSK_REFRESH_TOKEN_EXPIRATION_KEY,
  LOCAL_STORAGE_KIOSK_REFRESH_TOKEN_KEY,
  LOCAL_STORAGE_KIOSK_TOKEN_KEY,
  TOAST_DISMISS_DURATION_IN_MILLISECONDS,
} from '@abbadox-monorepo/kiosk-core-constants';
import { IdsNotificationComponent, IdsToast, notificationStatusColorMap } from '@abbadox-monorepo/shared-ui';

import {
  ACCOUNT_SETTTING_WORKTYPES,
  AccountLocation,
  defaultLocation,
  defaultSetting,
  initialUserState,
} from './models/user.models';
import { AuthHttpClientService } from './services/auth-http-client.service';

const selectId: SelectEntityId<AccountLocation> = (location) => location.locationId;

export const AuthStore = signalStore(
  { providedIn: 'root' },
  withState(initialUserState),
  withEntities<AccountLocation>(),
  withSelectedEntity(),
  withRequestStatus(),
  withLogger('auth'),
  withComputed((state) => {
    const deviceLocation = computed(() => state.deviceLocations()[0]);
    const currentLocation = computed(
      () =>
        state
          .entities()
          .find((location) => String(location.locationId) === String(deviceLocation().schedulerLocationId)) ??
        defaultLocation,
    );
    const idsWorktype = computed(
      () => (state.settings().find((a) => a.name === ACCOUNT_SETTTING_WORKTYPES.ID_WORKTYPE) ?? defaultSetting).value,
    );
    const insuranceWorktype = computed(
      () =>
        (state.settings().find((a) => a.name === ACCOUNT_SETTTING_WORKTYPES.INSURANCE_WORKTYPE) ?? defaultSetting)
          .value,
    );

    return { currentLocation, idsWorktype, insuranceWorktype };
  }),
  withMethods((state) => ({
    resetState(): void {
      patchState(state, initialUserState);
    },
  })),
  withMethods(
    (
      state,
      authHttpClientService = inject(AuthHttpClientService),
      localStorageService = inject(LocalStorageService),
      router = inject(Router),
      toast = inject(IdsToast),
    ) => {
      function notifyLoginFailed(): void {
        toast.openFromComponent(IdsNotificationComponent, {
          data: {
            ...notificationStatusColorMap.get('error'),
            label: 'Authentication Failed',
            messages: ['Please check your username and password are correct and try again.'],
          },
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: TOAST_DISMISS_DURATION_IN_MILLISECONDS,
        });
      }

      const login = rxMethod<{ username: string; password: string }>(
        pipe(
          tap(() => patchState(state, setLoading())),
          switchMap(({ username, password }) =>
            authHttpClientService.loginKiosk({ username, password }).pipe(
              tapResponse({
                next: ({ access_token, refresh_token, expires_at }) => {
                  localStorageService.setItem(LOCAL_STORAGE_KIOSK_TOKEN_KEY, access_token);
                  localStorageService.setItem(LOCAL_STORAGE_KIOSK_REFRESH_TOKEN_KEY, refresh_token);
                  localStorageService.setItem(
                    LOCAL_STORAGE_KIOSK_REFRESH_TOKEN_EXPIRATION_KEY,
                    getFutureDate(Number(expires_at) * 1000),
                  );
                  patchState(state, { access_token });
                  loadProfile();
                  loginCareflowAuthUser();
                  router.navigate(['/home']);
                },
                error: (error: HttpErrorResponse) => {
                  patchState(state, setError(error.message));
                  notifyLoginFailed();
                },
              }),
            ),
          ),
        ),
      );

      const loadProfile = rxMethod<void>(
        pipe(
          switchMap(() =>
            authHttpClientService.loadKioskProfile().pipe(
              tapResponse({
                next: (profile) => {
                  patchState(state, { ...profile }, setLoaded);
                  loadLocations();
                  loadAppSettings();
                },
                error: (error: HttpErrorResponse) => patchState(state, setError(error.message)),
              }),
            ),
          ),
        ),
      );

      const loadLocations = rxMethod<void>(
        pipe(
          switchMap(() =>
            authHttpClientService.getKioskProfileLocations().pipe(
              tapResponse({
                next: (locations) => patchState(state, setAllEntities(locations, { selectId }), setLoaded),
                error: (error: HttpErrorResponse) => patchState(state, setError(error.message)),
              }),
            ),
          ),
        ),
      );

      const loadAppSettings = rxMethod<void>(
        pipe(
          switchMap(() =>
            authHttpClientService.getAppSettings({ applicationName: ApplicationNames.PATIENT_VISIT_COMPANION }).pipe(
              tapResponse({
                next: (settings) => patchState(state, { settings }, setLoaded),
                error: (error: HttpErrorResponse) => patchState(state, setError(error.message)),
              }),
            ),
          ),
        ),
      );

      const loginCareflowAuthUser = rxMethod<void>(
        pipe(
          switchMap(() =>
            authHttpClientService.loginCareflow().pipe(
              tapResponse({
                next: ({ token: careflow_auth_token }) => {
                  localStorageService.setItem(LOCAL_STORAGE_KIOSK_CAREFLOW_TOKEN_KEY, careflow_auth_token);
                  patchState(state, { careflow_auth_token }, setLoaded);
                  loginCareflowUser();
                },
                error: (error: HttpErrorResponse) => patchState(state, setError(error.message)),
              }),
            ),
          ),
        ),
      );

      const loginCareflowUser = rxMethod<void>(
        pipe(
          switchMap(() =>
            authHttpClientService.convertToEformsToken().pipe(
              tapResponse({
                next: ({ token: careflow_api_token }) => {
                  localStorageService.setItem(LOCAL_STORAGE_KIOSK_CAREFLOW_CONVERTED_TOKEN_KEY, careflow_api_token);
                  patchState(state, { careflow_api_token }, setLoaded);
                },
                error: (error: HttpErrorResponse) => patchState(state, setError(error.message)),
              }),
            ),
          ),
        ),
      );

      function logout(): void {
        localStorageService.clearAll();
        router.navigate(['/login']);
      }

      return { login, loadProfile, loadLocations, loadAppSettings, loginCareflowAuthUser, loginCareflowUser, logout };
    },
  ),
);
