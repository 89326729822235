import { Directive, HostListener, inject } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[idsDateOnly]',
  exportAs: 'idsDateOnly',
  standalone: true,
})
export class IdsDateOnlyDirective {
  public ngControl = inject(NgControl);

  @HostListener('keydown', ['$event'])
  onKeydown(event: any) {
    let newVal = event.target.value.replace(/\D/g, '');
    console.log(event.key);
    console.log('change', event.target.value.replace(/\D/g, ''));

    this.ngControl.valueAccessor?.writeValue(newVal);
  }
}
