import { AccountSettingsEntity } from '@abbadox-monorepo/core-api-interfaces';
import { Profile } from '@abbadox-monorepo/kiosk-core-api-interfaces';

export type User = Profile & {
  access_token: string;
  careflow_auth_token: string;
  careflow_api_token: string;
  locations: AccountLocation[];
  settings: AccountSettingsEntity[];
};

export type AccountLocation = {
  locationId: number;
  locationName: string;
};

export const initialUserState: User = {
  accountConfigId: 0,
  accountName: '',
  username: '',
  logo: null,
  deviceUID: null,
  kioskConfigurationId: 0,
  isDeviceLock: null,
  deviceLocations: [],
  access_token: '',
  careflow_auth_token: '',
  careflow_api_token: '',
  locations: [],
  settings: [],
};

export const defaultLocation: AccountLocation = {
  locationId: 0,
  locationName: '',
};

export const defaultSetting: AccountSettingsEntity = {
  id: 0,
  name: '',
  value: '',
};

export const enum ACCOUNT_SETTTING_WORKTYPES {
  ID_WORKTYPE = 'Government ID Upload Worktype',
  INSURANCE_WORKTYPE = 'Insurance Card Upload Worktype',
}
