import { defer, first, isObservable, switchMap, Observable, of, shareReplay } from 'rxjs';

/**
 * Caches an observable source for a period of time.
 */
export const createCacheSource = <T>(source: Observable<T>, time?: number, bufferReplays = 1) =>
  source.pipe(shareReplay(bufferReplays, time));

/**
 * Resubscribes a cached observable after time expires. If no time provided, cache won't expire.
 *
 * @param source - source observable to cache
 * @param time - max time to cache emitted values
 * @param bufferReplays - max replays to share for cached observable
 * @returns - cached observable
 * @source https://www.prestonlamb.com/blog/rxjs-cache-and-refresh-in-angular
 */
export const createResubSource = <T>(source: Observable<T>, time?: number, bufferReplays = 1): Observable<T> =>
  createCacheSource<T>(source, time, bufferReplays).pipe(
    first(
      null,
      defer(() => createCacheSource<T>(source, time, bufferReplays)),
    ),
    switchMap((d) => (isObservable(d) ? d : of(d))),
  );
