import { computed } from '@angular/core';

import { signalStoreFeature, type, withComputed, withState } from '@ngrx/signals';
import { EntityId, EntityState } from '@ngrx/signals/entities';

export type SelectedEntityState = { selectedEntityId: EntityId | null };

/**
 * The withSelectedEntity feature adds the selectedEntityId state slice and the selectedEntity
 * computed signal to the store where it is used. However, it expects state properties from
 * the EntityState type to be defined in that store. These properties can be added to the store
 * by using the withEntities feature from the entities plugin.
 *
 * @example A `BooksStore` instance will contain the following properties:
 * State signals from withEntities feature:
 * - entityMap: Signal<EntityMap<Book>>
 * - ids: Signal<EntityId[]>
 * Computed signals from withEntities feature:
 * - entities: Signal<Book[]>
 * State signals from withSelectedEntity feature:
 * - selectedEntityId: Signal<EntityId | null>
 * Computed signals from withSelectedEntity feature:
 * - selectedEntity: Signal<Book | null>
 *
 * @returns - signal feature
 */
export function withSelectedEntity<Entity>() {
  return signalStoreFeature(
    { state: type<EntityState<Entity>>() },
    withState<SelectedEntityState>({ selectedEntityId: null }),
    withComputed(({ entityMap, selectedEntityId }) => ({
      selectedEntity: computed(() => {
        const selectedId = selectedEntityId();
        return selectedId ? entityMap()[selectedId] : null;
      }),
    })),
  );
}
