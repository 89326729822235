import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  InjectionToken,
  input,
  model,
  numberAttribute,
  output,
  viewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/bs-datepicker.config';

/**
 * Object that can be used to configure the default options for the button component.
 * IdsDatePickerConfig derives its settings from the underlying BsDatepickerConfig.
 */
export interface IdsDatePickerConfig extends Partial<BsDatepickerConfig> {}

/** Injection token that can be used to provide the default options the button component. */
export const IDS_DATE_PICKER_CONFIG = new InjectionToken<IdsDatePickerConfig>('IDS_DATE_PICKER_CONFIG', {
  factory: () => ({
    /** Which view to start on */
    startView: 'day',

    /** Whether to show the today click handler */
    showTodayButton: true,

    /** Allows to hide week numbers in datepicker */
    showWeekNumbers: false,

    /** Sets the output format of date after validation */
    dateInputFormat: 'MM/DD/YYYY',

    /** Sets the output format of date after validation */
    adaptivePosition: true,

    /** Uses internal focus monitoring to return focus after date selection */
    returnFocusToInput: false,
  }),
});

@Component({
  selector: 'ids-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class IdsDatePicker {
  readonly dp = viewChild<BsDatepickerDirective>('dp');
  readonly defaultConfig = inject(IDS_DATE_PICKER_CONFIG, { optional: true });

  /**
   * Name of the form control to map to.
   *
   * The `date` name must exist in a wrapping formGroup.
   *
   */
  readonly formGroup = input.required<FormGroup>();

  /** Name of the form group field from the form group. */
  readonly formControlName = input('date');

  /**
   * Input field type for the date picker.
   *
   * Specifying the type doesn't affect desktop apps
   * but determines the type of mobile keyboard to display.
   */
  readonly type = input('text');

  /** Placholder test to show in the input field. */
  readonly placeholder = input('');

  /**
   * Determines the event that can trigger the datepicker.
   *
   * Valid events are a string separated by a comma per event type.
   */
  readonly triggers = input('dblclick:click');

  /** Whether the input is disabled. */
  readonly disabled = input(false, { transform: booleanAttribute });

  /** Toggles datepicker's popover visibility. */
  readonly isOpen = input(false, { alias: 'open', transform: booleanAttribute });

  /**
   * Config options for the datepicker.
   *
   * Settings matching global config will be overriden.
   */
  readonly config = model<Partial<BsDatepickerConfig>>({});
  _config = computed(() => ({ ...this.defaultConfig, ...this.config() }));

  /** Tabindex of the input field. */
  tabindex = input(0, { transform: numberAttribute });

  /** Tabindex of the input icon trigger. */
  iconTabindex = input(0);

  /** Track changes to the input's value from the datepicker directive directly. */
  readonly onDatepickerValueChange = output<Date>();

  onValueChange(date: Date) {
    if (!date) {
      return;
    }

    this.onDatepickerValueChange.emit(date);
  }
}
