import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { Store } from '@ngrx/store';
import { BehaviorSubject, catchError, filter, switchMap, take, tap, throwError } from 'rxjs';

import { ErrorHandlerActions } from '@abbadox-monorepo/core-error-handler';
import { filterByURLs, LocalStorageService } from '@abbadox-monorepo/core-utils';
import {
  CAREFLOW_API_ENDPOINTS,
  LOCAL_STORAGE_KIOSK_REFRESH_TOKEN_EXPIRATION_KEY,
  LOCAL_STORAGE_KIOSK_REFRESH_TOKEN_KEY,
  LOCAL_STORAGE_KIOSK_TOKEN_KEY,
} from '@abbadox-monorepo/kiosk-core-constants';

import { AuthStore } from '../auth.store';
import { AuthHttpClientService } from './auth-http-client.service';

const addTokenHeader = (request: HttpRequest<unknown>, token: string | null | undefined) => {
  return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
};

/**
 * Token interceptor that applies a stored access token to each API request.
 */
export const kioskTokenInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const store = inject(Store);
  const authStore = inject(AuthStore);

  const authHttpClientService = inject(AuthHttpClientService);
  const localStorageService = inject(LocalStorageService);
  const token = toSignal(localStorageService.getItem<string>(LOCAL_STORAGE_KIOSK_TOKEN_KEY));
  const refreshToken = toSignal(localStorageService.getItem<string>(LOCAL_STORAGE_KIOSK_REFRESH_TOKEN_KEY));
  const expiresAt = toSignal(localStorageService.getItem<string>(LOCAL_STORAGE_KIOSK_REFRESH_TOKEN_EXPIRATION_KEY));
  const refreshTokenSubject = new BehaviorSubject<string | null>(null);

  // if valid token for request, proceed
  if (token() && !filterByURLs(request.url, Object.values(CAREFLOW_API_ENDPOINTS))) {
    request = addTokenHeader(request, token());
  }

  let isRefreshing = false;

  const handle401Error = () => {
    if (!isRefreshing) {
      isRefreshing = true;
      refreshTokenSubject.next(null);

      const expiresIn = Number(expiresAt());

      if (new Date(expiresIn) < new Date()) {
        authStore.logout();
      }

      if (refreshToken())
        return authHttpClientService.refreshLogin(refreshToken() ?? '').pipe(
          tap(({ access_token }) => {
            localStorageService.setItem(LOCAL_STORAGE_KIOSK_TOKEN_KEY, access_token);
            // NOTE: new refresh token and expiration time isn't stored with a new token to keep some control over invalidation
            refreshTokenSubject.next(access_token);
            authStore.loginCareflowAuthUser(); // trigger other tokens to refresh
          }),
          switchMap(({ access_token }) => {
            isRefreshing = false;
            return next(addTokenHeader(request, access_token));
          }),
          catchError((error: HttpErrorResponse) => {
            isRefreshing = false;
            store.dispatch(ErrorHandlerActions.handleError401({ message: error.message, status: error.status }));
            return throwError(() => new Error(JSON.stringify(error)));
          }),
        );
    }

    return refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next(addTokenHeader(request, token))),
    );
  };

  // else refresh tokens and retry
  return next(request).pipe(
    catchError((error) => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          return handle401Error();
        }
      }

      return throwError(() => new Error(JSON.stringify(error)));
    }),
  );
};
