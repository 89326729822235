import { HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '@abbadox-monorepo/core-http-client';
import { createResubSource } from '@abbadox-monorepo/core-utils';

import { KIOSK_API_URL } from './tokens';

@Injectable({ providedIn: 'root' })
export class KioskApiHttpService {
  private readonly http = inject(HttpBaseService);
  private readonly apiUrl = inject(KIOSK_API_URL);

  get<T>(url: string, params: HttpParams = new HttpParams(), staleTime?: number): Observable<T> {
    return createResubSource(this.http.get<T>(`${this.apiUrl}${url}`, params), staleTime);
  }

  post<T, D>(url: string, data?: D): Observable<T> {
    return this.http.post<T, D>(`${this.apiUrl}${url}`, data);
  }

  put<T, D>(url: string, data: D): Observable<T> {
    return this.http.put<T, D>(`${this.apiUrl}${url}`, data);
  }
}
