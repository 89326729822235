import { Directive, HostListener } from '@angular/core';

import { TEXT_INPUT_PATTERN } from '.';

@Directive({
  selector: 'input[idsLettersOnlyMask]',
  exportAs: 'idsLetterOnlyMask',
  standalone: true,
})
export class IdsLettersOnlyMask {
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (!new RegExp(TEXT_INPUT_PATTERN).test(event.key)) {
      event.preventDefault();
    }
  }
}
