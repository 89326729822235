import { InjectionToken } from '@angular/core';

import { CareflowLoginUserDto, CareflowAuthTokenConverterDto } from '@abbadox-monorepo/kiosk-core-api-interfaces';

export const KIOSK_API_URL = new InjectionToken<string>('KIOSK_API_URL');
export const KIOSK_APP_VERSION = new InjectionToken<string>('CAREFLOW_API_URL');

/** Uploadcare configs */
export type UploadcareConfig = {
  pubKey: string;
  secret: string;
  expires: number;
  previewProxy: string;
};

export const UPLOADCARE_CONFIG = new InjectionToken<UploadcareConfig>('UPLOADCARE_CONFIG');

/** Careflow API-speicfic configs */
export const KIOSK_CAREFLOW_USER_CONFIG = new InjectionToken<CareflowLoginUserDto>('KIOSK_CAREFLOW_USER_CONFIG');
export const KIOSK_CAREFLOW_TOKEN_CONVERTER_CONFIG = new InjectionToken<CareflowAuthTokenConverterDto>(
  'KIOSK_CAREFLOW_TOKEN_CONVERTER_CONFIG',
);
