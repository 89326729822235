export const hasProperties = <T extends object>(object: T) => Boolean(Object.keys(object).length);

/**
 * Check at least one value exists in the object.
 *
 * @param object - object with values to check
 * @returns boolean if values exists in the object
 */
export const hasSomeValues = <T extends object>(object: T): boolean => {
  if (!hasProperties(object)) {
    return false;
  }

  return Object.values(object).some((v) => Boolean(v));
};

/**
 * Check that all values exist in the object.
 *
 * @param object - object with values to check
 * @returns boolean if values exists in the object
 */
export const hasAllValues = <T extends object>(object: T): boolean => {
  if (!hasProperties(object)) {
    return false;
  }

  return Object.values(object).every((v) => Boolean(v));
};
