import { createActionGroup, createFeature, createReducer, on, props } from '@ngrx/store';

export const ErrorHandlerActions = createActionGroup({
  source: 'Auth API',
  events: {
    handleError401: props<{ message: string; status: number }>(),
    handleError404: props<{ message: string; status: number }>(),
    handleError500: props<{ message: string; status: number }>(),
  },
});

export interface ErrorHandlerState {
  code: number;
  message: string | undefined;
}

export const errorHandlerInitialState: ErrorHandlerState = {
  message: undefined,
  code: -1,
};

export const errorFeature = createFeature({
  name: 'error',
  reducer: createReducer(
    errorHandlerInitialState,
    on(
      ErrorHandlerActions.handleError401,
      ErrorHandlerActions.handleError404,
      ErrorHandlerActions.handleError500,
      (state, { message, status }) => ({
        ...state,
        code: status,
        message: message,
      }),
    ),
  ),
});

export const { name: ERROR_HANDLER_STATE_FEATURE_KEY, reducer: errorHandlerReducer } = errorFeature;
