import { Pipe, PipeTransform } from '@angular/core';

import { format, isValid } from 'date-fns';

@Pipe({
  name: 'shortTime',
  standalone: true,
})
export class ShortTimePipe implements PipeTransform {
  transform(value: string): any {
    if (!value) {
      return value;
    }

    value = value.replace(/T|Z/g, ' ').trim();
    const date = new Date(value);

    if (!isValid(date)) {
      return value;
    }

    return format(date, 'h:mm a');
  }
}
