import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';

import { first, mergeMap } from 'rxjs';

import { filterByURLs, LocalStorageService } from '@abbadox-monorepo/core-utils';
import {
  CAREFLOW_ENDPOINTS_USING_CONVERTED_TOKEN,
  LOCAL_STORAGE_KIOSK_CAREFLOW_CONVERTED_TOKEN_KEY,
} from '@abbadox-monorepo/kiosk-core-constants';

/**
 * Token interceptor that applies a stored access token to each API request.
 *
 * Uses the converted careflow token for endpoints requiring it.
 */
export const careflowConvertedTokenInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
) =>
  inject(LocalStorageService)
    .getItem<string>(LOCAL_STORAGE_KIOSK_CAREFLOW_CONVERTED_TOKEN_KEY)
    .pipe(
      first(),
      mergeMap((token) => {
        if (token && filterByURLs(request.url, Object.values(CAREFLOW_ENDPOINTS_USING_CONVERTED_TOKEN))) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
        }

        return next(request);
      }),
    );
