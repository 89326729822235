import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { IdsButton } from '../button/button.component';
import { IdsIcon } from '../icon';
import { DatePickerHarness } from './date-picker-harness.component';
import { IdsDatePicker } from './date-picker.component';

/**
 * This is a temporary module to declare legacy 3rd-party libraries.
 */
@NgModule({
  declarations: [IdsDatePicker, DatePickerHarness],
  imports: [CommonModule, ReactiveFormsModule, BsDatepickerModule.forRoot(), IdsIcon, IdsButton],
  exports: [ReactiveFormsModule, IdsDatePicker, DatePickerHarness],
})
export class DatePickerModule {}
