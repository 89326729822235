/**
 * Converts the solid version of a fontawesome icon to its regular version.
 *
 * @param iconClass - font awesome icon class to parse
 * @returns - modified icon class
 */
export const convertSolidToRegular = (iconClass: string | undefined | null): string => {
  if (!iconClass) {
    return '';
  }

  return iconClass.replace(/(fa|fas)\s/gm, 'far ');
};
