import { computed } from '@angular/core';

import { signalStoreFeature, withComputed, withState } from '@ngrx/signals';

export type RequestStatus = 'idle' | 'loading' | 'loaded' | { error: string };
export type RequestStatusState = { requestStatus: RequestStatus };

/**
 * The `withRequestStatus` feature and updaters can be used to add the `requestStatus`
 * state slice, along with the `loading`, `loaded`, and `error` computed signals
 * to a signal store.
 *
 * @example A `BooksStore` instance will contain the following properties and methods:
 * State signals from withRequestStatus feature:
 * - requestStatus: Signal<RequestStatus>
 * Computed signals from withRequestStatus feature:
 * - loading: Signal<boolean>
 * - loaded: Signal<boolean>
 * - error: Signal<string | null>
 *
 * @returns - signal feature
 */
export function withRequestStatus() {
  return signalStoreFeature(
    withState<RequestStatusState>({ requestStatus: 'idle' }),
    withComputed(({ requestStatus }) => ({
      loading: computed(() => requestStatus() === 'loading'),
      loaded: computed(() => requestStatus() === 'loaded'),
      error: computed(() => {
        const status = requestStatus();
        return typeof status === 'object' ? status.error : null;
      }),
    })),
  );
}

// State updaters for modifying the request status
export function setLoading(): RequestStatusState {
  return { requestStatus: 'loading' };
}

export function setLoaded(): RequestStatusState {
  return { requestStatus: 'loaded' };
}

export function setError(error: string): RequestStatusState {
  return { requestStatus: { error } };
}
