import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { lastValueFrom, Observable } from 'rxjs';

import { AccountSettingsEntity, AccountSettingsQuery } from '@abbadox-monorepo/core-api-interfaces';
import { CareflowAuthHttpService, CareflowAuthConverterHttpService } from '@abbadox-monorepo/core-http-client';
import {
  KioskAuthTokenResponse,
  CareflowAuthTokenResponse,
  CareflowConverterTokenResponse,
  KioskLoginUserDto,
  CareflowLoginUserDto,
  CareflowAuthTokenConverterDto,
  Profile,
  LocationDTO,
} from '@abbadox-monorepo/kiosk-core-api-interfaces';
import {
  KIOSK_API_URL,
  KIOSK_CAREFLOW_TOKEN_CONVERTER_CONFIG,
  KIOSK_CAREFLOW_USER_CONFIG,
  KioskApiHttpService,
} from '@abbadox-monorepo/kiosk-core-http-client';

/**
 * Authentication service handling login.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthHttpClientService {
  private readonly http = inject(HttpClient);
  private readonly apiUrl = inject(KIOSK_API_URL);

  private readonly kioskApiHttpService = inject(KioskApiHttpService);
  private readonly careflowAuthHttpService = inject(CareflowAuthHttpService);
  private readonly careflowAuthConverterHttpService = inject(CareflowAuthConverterHttpService);

  private readonly careflowUserConfig = inject(KIOSK_CAREFLOW_USER_CONFIG);
  private readonly tokenConverterUser = inject(KIOSK_CAREFLOW_TOKEN_CONVERTER_CONFIG);

  loginKiosk(credentials: KioskLoginUserDto): Observable<KioskAuthTokenResponse> {
    return this.kioskApiHttpService.post<KioskAuthTokenResponse, KioskLoginUserDto>('/auth/login', credentials);
  }

  loginKioskAsPromise(credentials: KioskLoginUserDto): Promise<KioskAuthTokenResponse> {
    return lastValueFrom(this.loginKiosk(credentials));
  }

  refreshLogin(token: string) {
    return this.http.post<KioskAuthTokenResponse>(`${this.apiUrl}/auth/refresh`, null, {
      headers: new HttpHeaders({ Authorization: `Bearer ${token}` }),
    });
  }

  loadKioskProfile(): Observable<Profile> {
    return this.kioskApiHttpService.get<Profile>('/users/profile');
  }

  loadKioskProfileAsPromise(): Promise<Profile> {
    return lastValueFrom(this.loadKioskProfile());
  }

  getKioskProfileLocations(): Observable<LocationDTO[]> {
    return this.kioskApiHttpService.get<LocationDTO[]>('/locations');
  }

  loadKioskLocationsAsPromise(): Promise<LocationDTO[]> {
    return lastValueFrom(this.getKioskProfileLocations());
  }

  getAppSettings(query: AccountSettingsQuery): Observable<AccountSettingsEntity[]> {
    let params = new HttpParams();

    for (const [key, value] of Object.entries(query)) {
      if (key && value) {
        params = params.append(key, value);
      }
    }

    return this.kioskApiHttpService.get<AccountSettingsEntity[]>('/settings', params);
  }

  loadAppSettingsAsPromise(query: AccountSettingsQuery): Promise<AccountSettingsEntity[]> {
    return lastValueFrom(this.getAppSettings(query));
  }

  loginCareflow(): Observable<CareflowAuthTokenResponse> {
    return this.careflowAuthHttpService.post<CareflowAuthTokenResponse, CareflowLoginUserDto>(
      '/KioskAuth',
      this.careflowUserConfig,
    );
  }

  loginCareflowAsPromise(): Promise<CareflowAuthTokenResponse> {
    return lastValueFrom(this.loginCareflow());
  }

  convertToEformsToken(): Observable<CareflowConverterTokenResponse> {
    return this.careflowAuthConverterHttpService.post<CareflowConverterTokenResponse, CareflowAuthTokenConverterDto>(
      '/Convert',
      this.tokenConverterUser,
    );
  }

  convertCareflowTokenToEformsTokenAsPromise(): Promise<CareflowConverterTokenResponse> {
    return lastValueFrom(this.convertToEformsToken());
  }
}
