import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from './http-base-client.service';
import { CAREFLOW_TOKEN_CONVERTER_API_URL } from './tokens';

@Injectable({ providedIn: 'root' })
export class CareflowAuthConverterHttpService {
  private readonly apiBase = inject(HttpBaseService);
  private readonly apiUrl = inject(CAREFLOW_TOKEN_CONVERTER_API_URL);

  post<T, D>(url: string, data?: D): Observable<T> {
    return this.apiBase.post<T, D>(`${this.apiUrl}${url}`, data);
  }
}
