import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { AuthStore } from '../auth.store';

export const profileGuard: CanActivateFn = () => {
  const { loaded, loadProfile } = inject(AuthStore);

  if (!loaded()) {
    loadProfile();
  }

  return true;
};
