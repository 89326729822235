// Auth
export const LOCAL_STORAGE_KIOSK_TOKEN_KEY = 'kiosk_access_token';
export const LOCAL_STORAGE_KIOSK_REFRESH_TOKEN_KEY = 'kiosk_refresh_token';
export const LOCAL_STORAGE_KIOSK_REFRESH_TOKEN_EXPIRATION_KEY = 'kiosk_refresh_expires_at';
export const LOCAL_STORAGE_KIOSK_CAREFLOW_TOKEN_KEY = 'kiosk_careflow_token';
export const LOCAL_STORAGE_KIOSK_CAREFLOW_CONVERTED_TOKEN_KEY = 'kiosk_careflow_converted_token';
export const LOCAL_STORAGE_KIOSK_ACTIVE_PATIENT_TOKEN = 'kiosk_active_patient_token';

// Timer$
export const STARTING_VALUE_TO_EMIT = 1;
export const INTERVAL_TO_EMIT_IN_MILLISECONDS = 1000; // 1 second

// Toast
export const TOAST_DISMISS_DURATION_IN_MILLISECONDS = 3000; // 3 seconds

// Format Patterns
/**
 * Note: date formatters are used by `date-fns`. Create another const for each unique format.
 */
export const DATE_OF_BIRTH_STANDARD_FORMAT = 'MM/dd/yyyy';

// API filters
export const CAREFLOW_ENDPOINTS_NOT_USING_CONVERTED_TOKEN = {
  TokenConvert: 'Convert',
};

export const CAREFLOW_ENDPOINTS_USING_CONVERTED_TOKEN = {
  CareflowAppointmentsApi: 'AppointmentsApi',
  AuditApi: 'AuditApi',
};

export const NON_TENANT_ENDPOINTS_TO_IGNORE = {
  kioskAuth: 'auth',
  kioskAuthRefresh: 'refresh',
  kioskCareflowAuth: 'KioskAuth',
  ...CAREFLOW_ENDPOINTS_NOT_USING_CONVERTED_TOKEN,
  ...CAREFLOW_ENDPOINTS_USING_CONVERTED_TOKEN,
};

export const CAREFLOW_API_ENDPOINTS = {
  kioskCareflowAuth: NON_TENANT_ENDPOINTS_TO_IGNORE.kioskCareflowAuth,
  kioskAuthRefresh: NON_TENANT_ENDPOINTS_TO_IGNORE.kioskAuthRefresh,
  ...CAREFLOW_ENDPOINTS_NOT_USING_CONVERTED_TOKEN,
  ...CAREFLOW_ENDPOINTS_USING_CONVERTED_TOKEN,
};
