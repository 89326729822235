import { Component, input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { endOfToday } from 'date-fns';

@Component({
  selector: 'storybook-date-picker-harness',
  template: `
    <div class="bg-bg-cool w-auto p-12" [formGroup]="form">
      <span class="text-text-tertiary text-nowrap">DOB*</span>
      <ids-date-picker
        [formGroup]="form"
        placeholder="MM/DD/YYYY"
        [open]="false"
        [config]="{ maxDate }"
        tabindex="10"
      ></ids-date-picker>
    </div>
  `,
})
export class DatePickerHarness {
  maxDate = endOfToday();
  dob = input<Date | undefined>();
  form = new FormGroup({
    date: new FormControl(this.dob()),
  });
  disabled = input(false);
}
