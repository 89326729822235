import { inject, isDevMode } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, tap } from 'rxjs';

import { TOAST_DISMISS_DURATION_IN_MILLISECONDS } from '@abbadox-monorepo/kiosk-core-constants';
import { IdsToast } from '@abbadox-monorepo/shared-ui/lib/cdk/toast';
import { IdsNotificationComponent } from '@abbadox-monorepo/shared-ui/lib/components/notification/notification-container.component';

import { CoreUiActions } from './core.state';

export const logger$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      filter(() => isDevMode()),
      tap(console.log),
    ),
  { dispatch: false, functional: true },
);

/** Opens a toast notification. */
export const openToastNotification$ = createEffect(
  (actions$ = inject(Actions), toast = inject(IdsToast)) =>
    actions$.pipe(
      ofType(CoreUiActions.triggerToastOpen),
      tap(({ data, options }) => {
        options = options || {};
        const duration = options.duration ?? TOAST_DISMISS_DURATION_IN_MILLISECONDS;

        toast.openFromComponent(IdsNotificationComponent, {
          data,
          ...options,
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration,
        });
      }),
    ),
  { functional: true, dispatch: false },
);
