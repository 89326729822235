import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';

import { filterByURLs } from '@abbadox-monorepo/core-utils';
import { CAREFLOW_API_ENDPOINTS } from '@abbadox-monorepo/kiosk-core-constants';

import { AuthStore } from '../auth.store';

/**
 * Tenant interceptor that applies the account name of the current authenticated user to each API request.
 */
export const tenantInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const authStore = inject(AuthStore);

  if (!filterByURLs(request.url, Object.values(CAREFLOW_API_ENDPOINTS))) {
    const headers = request.headers.append('x-tenant-id', authStore.accountName());
    request = request.clone({
      headers,
    });
  }

  return next(request);
};
